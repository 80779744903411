import React from "react";
import "./popup.scss";

const Popup = ({ children, onClick, className }) => {
  return (
    <>
      <section id="popup-wrapper" className={className}>
        <div className="popup">
          <div className="icon">
            <div className="popup-icon" onClick={onClick}>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <path
                    d="M0 35.801C0.232619 34.5741 1.01567 33.7195 1.85725 32.8739C6.00586 28.708 10.1424 24.5309 14.2668 20.3425C14.3757 20.2324 14.5261 20.165 14.6728 20.0676C14.4831 19.8669 14.3883 19.762 14.289 19.6624C9.96458 15.2863 5.63767 10.9101 1.3083 6.534C0.634257 5.85909 0.236005 4.95253 0.192662 3.99441C0.149319 3.0363 0.464059 2.09676 1.07435 1.36247C1.68464 0.628179 2.54581 0.152871 3.48677 0.0309827C4.42773 -0.0909053 5.37961 0.149547 6.15328 0.704567C6.35263 0.852408 6.53947 1.01675 6.71187 1.19591C10.9978 5.50213 15.2817 9.8096 19.5637 14.1183C19.6926 14.2479 19.8022 14.397 19.9504 14.5677C20.103 14.4179 20.2141 14.3191 20.3208 14.2112C24.5835 9.90098 28.8465 5.59101 33.1096 1.28129C35.2106 -0.839113 38.6592 -0.0174652 39.477 2.80999C39.8889 4.23308 39.5926 5.5236 38.5399 6.59017C34.2703 10.9134 29.9982 15.2326 25.7236 19.5478C25.6132 19.6586 25.4851 19.7515 25.3228 19.8893C25.4969 20.0676 25.6288 20.2039 25.7622 20.3387C30.092 24.6924 34.4231 29.0453 38.7555 33.3975C39.5704 34.2214 40.0201 35.1853 39.9993 36.3575C39.9827 37.3172 39.5978 38.2329 38.9258 38.911C38.2538 39.5891 37.3475 39.9766 36.3982 39.9916C35.0647 40.0231 33.9579 39.5774 33.0037 38.6052C28.7874 34.307 24.5566 30.021 20.3112 25.7472C20.2084 25.6273 20.1148 25.4996 20.0311 25.3652L19.8622 25.3345C19.7778 25.4694 19.7141 25.6259 19.6059 25.736C15.3012 30.0966 10.9938 34.4548 6.68371 38.8105C5.36208 40.1467 3.10628 40.3908 1.53573 39.3715C0.730453 38.8472 0.312629 38.0705 0.084455 37.1619C0.0622303 37.075 0.0281513 36.9919 0 36.9073V35.801Z"
                    fill="#D6111E"
                  />
                </svg>
              </span>
            </div>
          </div>
          {children}
        </div>
      </section>
    </>
  );
};

export default Popup;
