import React from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import routes from "../../config/routes";

const Header = () => {
  return (
    <>
      <section id="header-wrapper">
        <Link to={routes.home}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="152"
            height="34"
            viewBox="0 0 152 34"
            fill="none"
          >
            <path
              d="M9.54218 16.6162L14.8589 9.31902H12.6533C11.7942 9.27255 10.9584 9.69086 10.4709 10.4113L7.66161 14.2225L4.94522 10.3416C4.43445 9.62114 3.59863 9.24931 2.71639 9.31902H0.487557L5.80425 16.6627L0 24.6106H2.08953C2.94856 24.6571 3.78437 24.2387 4.27193 23.5183L7.63839 18.9866L15.509 30.0951C15.9965 30.8155 16.8323 31.2338 17.6913 31.1873H19.9898L9.54218 16.6162ZM52.5401 24.6106H55.5815V9.31902H52.5401V24.6106ZM28.4176 24.6106H31.459V9.31902H28.4176V24.6106ZM74.5266 30.0254L85.5314 9.31902H83.883C83.0008 9.20283 82.1649 9.64438 81.7006 10.4113L77.359 18.638L73.6443 10.4113C73.2032 9.62114 72.3209 9.17959 71.4155 9.31902H69.8367L75.6178 21.7754L70.5797 31.1873H72.2281C73.1335 31.2571 74.039 30.8155 74.5266 30.0254ZM35.1041 9.31902V24.6106H38.1455V14.0831C39.0278 12.6887 40.5369 11.8521 42.1621 11.8521C44.4141 11.8521 45.8768 13.293 45.8768 16.1979V23.1232C45.8303 23.8902 46.4108 24.5641 47.1769 24.6106C47.2234 24.6106 47.2466 24.6106 47.293 24.6106H48.9182V15.6402C48.9182 11.6662 46.434 8.90071 42.8354 8.90071C41.0941 8.87748 39.3992 9.52818 38.1223 10.7366V9.31902H35.1041ZM61.2232 19.219C60.9911 22.1472 63.1502 24.7035 66.0291 24.9359C66.2845 24.9592 66.5631 24.9592 66.8185 24.9359C67.5615 24.9359 68.3276 24.843 69.0473 24.6338L68.4205 21.938C67.9561 22.031 67.4918 22.1007 67.0275 22.1007C65.6112 22.2169 64.3575 21.1479 64.2414 19.7071C64.2182 19.5444 64.2414 19.3585 64.2414 19.1958V12.038H68.4901L67.2596 9.34226H64.2414V3.09086L61.2 4.43874V9.31902H57.9728V12.0148H61.1768L61.2232 19.219ZM19.0612 12.0845V24.6803H22.1026V12.038H26.0263V9.31902H22.1258V8.0641C22.1258 5.43804 23.6581 4.57818 25.1672 4.57818C25.5155 4.57818 25.8637 4.62466 26.1888 4.74086L26.8156 1.9986C26.212 1.81269 25.5619 1.71973 24.9351 1.76621C21.2203 1.76621 19.0844 4.62466 19.0844 8.08733V9.34226H17.4824L15.5786 12.038L19.0612 12.0845Z"
              fill="white"
            />
            <path
              d="M105.382 8.92395C105.498 8.87748 105.661 8.80776 105.824 8.76128C105.986 8.7148 106.172 8.66832 106.381 8.64508C106.59 8.62184 106.799 8.5986 107.031 8.5986C107.333 8.5986 107.588 8.62184 107.82 8.69156C108.052 8.76128 108.238 8.85424 108.401 9.01691C108.563 9.15635 108.679 9.3655 108.772 9.5979C108.865 9.83029 108.888 10.1324 108.888 10.481V12.5725C108.888 12.9211 108.911 13.2232 108.981 13.4324C109.051 13.6416 109.12 13.8275 109.167 13.9437H108.354C108.331 13.874 108.285 13.781 108.238 13.6416C108.192 13.5021 108.145 13.3394 108.145 13.1535C107.959 13.4324 107.727 13.6416 107.449 13.8042C107.17 13.9669 106.822 14.0366 106.45 14.0366C106.218 14.0366 105.986 14.0134 105.777 13.9437C105.568 13.874 105.406 13.781 105.266 13.6648C105.127 13.5486 105.011 13.3859 104.941 13.2C104.872 13.0141 104.825 12.8049 104.825 12.5725C104.825 12.3169 104.872 12.0845 104.988 11.8754C105.104 11.6662 105.266 11.4803 105.522 11.3409C105.777 11.2014 106.102 11.0852 106.52 10.9923C106.938 10.8993 107.449 10.8528 108.076 10.8528V10.4345C108.076 10.2486 108.052 10.1092 108.029 9.96973C107.983 9.83029 107.936 9.7141 107.843 9.64438C107.751 9.57466 107.658 9.4817 107.518 9.43522C107.379 9.38874 107.217 9.3655 107.008 9.3655C106.729 9.3655 106.427 9.41198 106.172 9.4817C105.916 9.55142 105.638 9.64438 105.406 9.76057L105.104 9.13311C105.15 9.04015 105.266 8.97043 105.382 8.92395ZM107.124 11.4106C106.868 11.4571 106.636 11.5035 106.45 11.55C106.265 11.5965 106.125 11.6662 106.009 11.7359C105.893 11.8056 105.824 11.8754 105.754 11.9683C105.707 12.038 105.661 12.131 105.638 12.2007C105.615 12.2937 105.615 12.3634 105.615 12.4331C105.615 12.712 105.684 12.9444 105.847 13.0838C106.009 13.2465 106.241 13.3162 106.59 13.3162C106.799 13.3162 107.008 13.293 107.193 13.2233C107.379 13.1535 107.542 13.0606 107.658 12.9444C107.774 12.8282 107.89 12.6887 107.959 12.5028C108.029 12.3402 108.076 12.131 108.076 11.9218V11.2944C107.704 11.3409 107.379 11.3873 107.124 11.4106Z"
              fill="white"
            />
            <path
              d="M113.833 13.0141C113.74 13.2 113.624 13.3627 113.508 13.5021C113.392 13.6415 113.276 13.7345 113.136 13.8042C112.997 13.8739 112.858 13.9437 112.719 13.9669C112.579 13.9901 112.417 14.0134 112.254 14.0134C111.976 14.0134 111.743 13.9669 111.534 13.8739C111.326 13.781 111.14 13.6648 111 13.5021C110.861 13.3394 110.745 13.1535 110.652 12.9211C110.583 12.6887 110.536 12.4099 110.536 12.0613V8.6683H111.349V12.2239C111.349 12.3634 111.372 12.5028 111.395 12.6422C111.442 12.7817 111.511 12.8979 111.604 13.0141C111.697 13.107 111.813 13.1768 111.952 13.2232C112.092 13.2697 112.254 13.3162 112.463 13.3162C112.672 13.3162 112.858 13.2697 113.02 13.2C113.183 13.1303 113.322 13.0373 113.438 12.9211C113.554 12.8049 113.624 12.6655 113.694 12.5028C113.74 12.3401 113.787 12.1775 113.787 12.0148V8.6683H114.599V13.0141L114.692 13.9437H113.926L113.833 13.0141Z"
              fill="white"
            />
            <path
              d="M117.362 8.66831H118.593V9.29577H117.362V12.6655C117.362 12.7584 117.362 12.8282 117.385 12.8979C117.409 12.9676 117.432 13.0373 117.478 13.0838C117.525 13.1303 117.571 13.1768 117.641 13.2C117.711 13.2232 117.78 13.2465 117.873 13.2465H118.662L118.616 13.8739C118.57 13.8972 118.523 13.9204 118.477 13.9204C118.43 13.9437 118.361 13.9437 118.291 13.9437C118.221 13.9437 118.152 13.9437 118.059 13.9669C117.966 13.9669 117.873 13.9669 117.757 13.9669C117.571 13.9669 117.385 13.9437 117.246 13.8739C117.107 13.8042 116.968 13.7345 116.875 13.6183C116.782 13.5021 116.689 13.3859 116.643 13.2465C116.596 13.107 116.573 12.9444 116.573 12.7817V9.31901H115.714V8.69155H116.573L116.666 7.29718H117.385V8.66831H117.362Z"
              fill="white"
            />
            <path
              d="M120.636 9.52818C120.728 9.34226 120.845 9.17959 120.937 9.06339C121.054 8.94719 121.17 8.85423 121.286 8.78452C121.402 8.7148 121.541 8.66832 121.68 8.64508C121.82 8.62184 121.982 8.5986 122.145 8.5986C122.423 8.5986 122.655 8.64508 122.864 8.73804C123.073 8.83099 123.259 8.94719 123.398 9.10987C123.538 9.27254 123.654 9.45846 123.747 9.69085C123.816 9.92325 123.863 10.2021 123.863 10.5507V13.9437H123.05V10.388C123.05 10.2486 123.027 10.1092 122.981 9.96973C122.934 9.83029 122.864 9.71409 122.772 9.5979C122.679 9.50494 122.563 9.43522 122.447 9.3655C122.307 9.31902 122.145 9.27254 121.936 9.27254C121.727 9.27254 121.541 9.31902 121.379 9.38874C121.216 9.45846 121.077 9.55142 120.961 9.66761C120.845 9.78381 120.775 9.92325 120.705 10.0859C120.636 10.2486 120.612 10.4113 120.612 10.574V13.9437H119.8V6.41409H120.612V9.52818H120.636Z"
              fill="white"
            />
            <path
              d="M128.529 8.80776C128.808 8.94719 129.063 9.13311 129.249 9.3655C129.458 9.5979 129.597 9.87677 129.714 10.2254C129.83 10.574 129.876 10.9225 129.876 11.3176C129.876 11.6894 129.83 12.038 129.714 12.3634C129.621 12.6887 129.458 12.9676 129.272 13.2233C129.087 13.4789 128.831 13.6648 128.553 13.8042C128.274 13.9437 127.949 14.0134 127.578 14.0134C127.229 14.0134 126.904 13.9437 126.602 13.8275C126.324 13.688 126.068 13.5021 125.883 13.2697C125.697 13.0373 125.534 12.7352 125.418 12.4099C125.302 12.0845 125.256 11.7127 125.256 11.2944C125.256 10.9225 125.302 10.574 125.418 10.2486C125.511 9.92325 125.674 9.64438 125.859 9.38874C126.068 9.13311 126.301 8.94719 126.579 8.80776C126.858 8.66832 127.206 8.5986 127.554 8.5986C127.926 8.5986 128.251 8.66832 128.529 8.80776ZM126.881 9.45846C126.695 9.57466 126.556 9.7141 126.44 9.87677C126.324 10.0394 126.231 10.2718 126.185 10.5042C126.138 10.7366 126.115 11.0155 126.115 11.2944C126.115 11.55 126.138 11.8056 126.185 12.038C126.231 12.2704 126.324 12.5028 126.417 12.6887C126.533 12.8747 126.672 13.0373 126.858 13.1535C127.044 13.2697 127.276 13.3162 127.554 13.3162C127.833 13.3162 128.042 13.2697 128.228 13.1535C128.413 13.0373 128.553 12.8979 128.669 12.712C128.785 12.5261 128.878 12.3169 128.924 12.0613C128.971 11.8056 128.994 11.55 128.994 11.2711C128.994 11.0155 128.971 10.7831 128.924 10.5275C128.878 10.2951 128.785 10.0859 128.692 9.87677C128.576 9.69086 128.437 9.55142 128.251 9.43522C128.065 9.31902 127.833 9.27255 127.554 9.27255C127.299 9.29579 127.067 9.3655 126.881 9.45846Z"
              fill="white"
            />
            <path
              d="M132.105 9.5979C132.175 9.45846 132.267 9.34226 132.337 9.22607C132.407 9.10987 132.523 9.01691 132.616 8.92395C132.709 8.831 132.825 8.76128 132.941 8.7148C133.057 8.66832 133.196 8.64508 133.335 8.64508C133.405 8.64508 133.498 8.64508 133.591 8.66832C133.684 8.66832 133.777 8.69156 133.893 8.7148L133.8 9.57466C133.684 9.55142 133.568 9.52818 133.498 9.52818C133.428 9.52818 133.335 9.50494 133.266 9.50494C132.917 9.50494 132.639 9.5979 132.43 9.78381C132.221 9.96973 132.128 10.2486 132.128 10.5972V13.9437H131.316V9.5979L131.223 8.69156H131.989L132.105 9.5979Z"
              fill="white"
            />
            <path
              d="M135.82 7.34367H134.892V6.41409H135.82V7.34367ZM135.751 13.9437H134.938V8.66832H135.751V13.9437Z"
              fill="white"
            />
            <path
              d="M140.788 9.20283L138.025 13.3162H140.881V13.9437H137.073V13.4324L139.836 9.31902H137.305V8.69156H140.788V9.20283Z"
              fill="white"
            />
            <path
              d="M142.669 12.131C142.715 12.3634 142.808 12.5493 142.924 12.7352C143.04 12.9211 143.203 13.0606 143.412 13.1535C143.621 13.2697 143.876 13.3162 144.201 13.3162C144.34 13.3162 144.48 13.293 144.619 13.2697C144.758 13.2465 144.874 13.2 144.99 13.1535C145.106 13.1071 145.223 13.0373 145.339 12.9676C145.455 12.8979 145.571 12.8282 145.687 12.7352L146.012 13.3859C145.757 13.5718 145.478 13.7345 145.199 13.8275C144.921 13.9204 144.572 13.9902 144.178 13.9902C143.76 13.9902 143.388 13.9204 143.063 13.781C142.762 13.6416 142.506 13.4556 142.297 13.2233C142.088 12.9909 141.949 12.712 141.856 12.3866C141.763 12.0613 141.717 11.7127 141.717 11.3409C141.717 10.9458 141.763 10.574 141.879 10.2254C141.995 9.87677 142.135 9.5979 142.344 9.34227C142.553 9.08663 142.785 8.90072 143.087 8.76128C143.388 8.62184 143.713 8.55212 144.062 8.55212C144.387 8.55212 144.689 8.62184 144.944 8.73804C145.199 8.85424 145.408 9.01691 145.594 9.22607C145.78 9.43522 145.896 9.69086 145.989 9.96973C146.082 10.2486 146.128 10.574 146.128 10.8993C146.128 10.9923 146.128 11.062 146.128 11.1317C146.128 11.2014 146.105 11.2944 146.105 11.4106H142.553C142.599 11.6894 142.622 11.9218 142.669 12.131ZM143.528 9.38874C143.365 9.45846 143.226 9.55142 143.11 9.69086C142.994 9.83029 142.878 9.99297 142.808 10.1789C142.715 10.3648 142.669 10.5972 142.645 10.8296H145.339C145.339 10.6204 145.315 10.4113 145.246 10.2254C145.176 10.0394 145.106 9.87677 144.99 9.73734C144.874 9.5979 144.758 9.4817 144.596 9.41198C144.433 9.34227 144.247 9.29579 144.038 9.29579C143.876 9.27255 143.69 9.31903 143.528 9.38874Z"
              fill="white"
            />
            <path
              d="M151.654 13.9437H150.98L150.887 13.1535C150.771 13.3162 150.655 13.4556 150.539 13.5718C150.423 13.688 150.284 13.781 150.145 13.8507C150.005 13.9204 149.866 13.9669 149.727 13.9901C149.587 14.0134 149.448 14.0366 149.286 14.0366C149.007 14.0366 148.775 13.9901 148.566 13.9204C148.357 13.8507 148.171 13.7345 148.032 13.5951C147.869 13.4556 147.753 13.293 147.66 13.1071C147.567 12.9211 147.475 12.7352 147.428 12.5261C147.382 12.3169 147.335 12.1078 147.312 11.8986C147.289 11.6894 147.266 11.4803 147.266 11.2944C147.266 10.9225 147.312 10.574 147.382 10.2486C147.475 9.92325 147.591 9.64438 147.753 9.38874C147.916 9.13311 148.148 8.94719 148.403 8.80776C148.659 8.66832 148.984 8.5986 149.355 8.5986C149.703 8.5986 150.005 8.66832 150.237 8.78452C150.47 8.92395 150.679 9.13311 150.841 9.41198V6.41409H151.654V13.9437ZM150.771 10.1324C150.725 9.99297 150.632 9.85353 150.539 9.71409C150.446 9.57466 150.284 9.4817 150.121 9.41198C149.959 9.34226 149.75 9.29578 149.494 9.29578C149.309 9.29578 149.123 9.31902 148.984 9.3655C148.844 9.41198 148.728 9.4817 148.612 9.5979C148.496 9.71409 148.426 9.80705 148.357 9.92325C148.287 10.0394 148.241 10.1789 148.194 10.3416C148.148 10.481 148.125 10.6437 148.125 10.8063C148.101 10.969 148.101 11.1317 148.101 11.2944C148.101 11.5268 148.125 11.7824 148.148 12.0148C148.171 12.2472 148.241 12.4796 148.334 12.6655C148.426 12.8514 148.566 13.0141 148.752 13.1303C148.937 13.2465 149.169 13.3162 149.471 13.3162C149.703 13.3162 149.912 13.2697 150.098 13.2C150.261 13.1303 150.4 13.0141 150.516 12.8979C150.632 12.7817 150.702 12.6423 150.748 12.4796C150.795 12.3401 150.818 12.1775 150.818 12.038V10.5507C150.841 10.4345 150.818 10.2951 150.771 10.1324Z"
              fill="white"
            />
            <path
              d="M106.009 20.381C106.079 20.2415 106.172 20.1253 106.241 20.0091C106.311 19.893 106.427 19.8 106.52 19.707C106.613 19.6141 106.729 19.5444 106.845 19.4979C106.961 19.4514 107.1 19.4282 107.24 19.4282C107.309 19.4282 107.402 19.4282 107.495 19.4514C107.588 19.4514 107.681 19.4746 107.797 19.4979L107.704 20.3577C107.588 20.3345 107.472 20.3113 107.402 20.3113C107.333 20.3113 107.24 20.288 107.17 20.288C106.822 20.288 106.543 20.381 106.334 20.5669C106.125 20.7528 106.032 21.0317 106.032 21.3803V24.6803H105.22V20.381L105.127 19.4746H105.893L106.009 20.381Z"
              fill="white"
            />
            <path
              d="M109.399 22.9141C109.446 23.1465 109.538 23.3324 109.655 23.5183C109.771 23.7042 109.933 23.8437 110.142 23.9366C110.351 24.0528 110.606 24.0993 110.931 24.0993C111.071 24.0993 111.21 24.0761 111.349 24.0528C111.489 24.0296 111.605 23.9831 111.721 23.9366C111.837 23.8901 111.953 23.8204 112.069 23.7507C112.185 23.681 112.301 23.6113 112.417 23.5183L112.742 24.169C112.487 24.3549 112.208 24.5176 111.93 24.6106C111.651 24.7035 111.303 24.7732 110.908 24.7732C110.49 24.7732 110.119 24.7035 109.794 24.5641C109.492 24.4246 109.237 24.2387 109.028 24.0063C108.819 23.7739 108.679 23.4951 108.587 23.1697C108.494 22.8444 108.447 22.4958 108.447 22.1239C108.447 21.7289 108.494 21.357 108.61 21.0084C108.726 20.6599 108.865 20.381 109.074 20.1253C109.283 19.8697 109.515 19.6838 109.817 19.5444C110.119 19.4049 110.444 19.3352 110.792 19.3352C111.117 19.3352 111.419 19.4049 111.674 19.5211C111.93 19.6373 112.139 19.8 112.324 20.0091C112.51 20.2183 112.626 20.4739 112.719 20.7528C112.812 21.0317 112.858 21.357 112.858 21.6824C112.858 21.7753 112.858 21.8451 112.858 21.9148C112.858 21.9845 112.835 22.0775 112.835 22.1937H109.283C109.33 22.4725 109.353 22.7049 109.399 22.9141ZM110.258 20.1718C110.096 20.2415 109.956 20.3345 109.84 20.4739C109.724 20.6134 109.608 20.7761 109.538 20.962C109.446 21.1479 109.399 21.3803 109.376 21.6127H112.092C112.092 21.4035 112.069 21.1944 111.999 21.0084C111.93 20.8225 111.86 20.6599 111.744 20.5204C111.628 20.381 111.512 20.2648 111.349 20.1951C111.187 20.1253 111.001 20.0789 110.792 20.0789C110.606 20.0556 110.421 20.1021 110.258 20.1718Z"
              fill="white"
            />
            <path
              d="M115.296 19.4514H116.526V20.0789H115.296V23.4718C115.296 23.5648 115.296 23.6345 115.319 23.7042C115.342 23.774 115.366 23.8437 115.412 23.8902C115.458 23.9366 115.505 23.9831 115.574 24.0063C115.644 24.0296 115.714 24.0528 115.807 24.0528H116.596L116.55 24.6803C116.503 24.7035 116.457 24.7268 116.41 24.7268C116.364 24.75 116.294 24.75 116.225 24.75C116.155 24.75 116.085 24.75 115.992 24.7732C115.9 24.7732 115.807 24.7732 115.691 24.7732C115.505 24.7732 115.319 24.75 115.18 24.6803C115.04 24.6106 114.901 24.5409 114.808 24.4247C114.715 24.3085 114.623 24.1923 114.576 24.0528C114.53 23.9134 114.506 23.7507 114.506 23.588V20.1021H113.647V19.4747H114.506L114.599 18.0803H115.319V19.4514H115.296Z"
              fill="white"
            />
            <path
              d="M117.896 19.7071C118.012 19.6606 118.175 19.5909 118.337 19.5444C118.5 19.4979 118.685 19.4514 118.894 19.4282C119.103 19.405 119.312 19.3817 119.544 19.3817C119.846 19.3817 120.102 19.405 120.334 19.4747C120.566 19.5444 120.752 19.6373 120.914 19.8C121.077 19.9395 121.193 20.1486 121.286 20.381C121.379 20.6134 121.402 20.9155 121.402 21.2641V23.3557C121.402 23.7042 121.425 24.0064 121.495 24.2155C121.564 24.4247 121.634 24.6106 121.68 24.7268H120.868C120.845 24.6571 120.798 24.5641 120.752 24.4247C120.705 24.2852 120.659 24.1226 120.659 23.9366C120.473 24.2155 120.241 24.4247 119.962 24.5873C119.684 24.75 119.336 24.8197 118.964 24.8197C118.732 24.8197 118.5 24.7965 118.291 24.7268C118.082 24.6571 117.919 24.5641 117.78 24.4479C117.641 24.3317 117.525 24.169 117.455 23.9831C117.385 23.7972 117.339 23.5881 117.339 23.3557C117.339 23.1 117.385 22.8676 117.501 22.6585C117.617 22.4493 117.78 22.2634 118.035 22.124C118.291 21.9845 118.616 21.8683 119.034 21.7754C119.452 21.6824 119.962 21.6359 120.589 21.6359V21.2176C120.589 21.0317 120.566 20.8923 120.543 20.7528C120.496 20.6134 120.45 20.4972 120.357 20.4275C120.264 20.3578 120.171 20.2648 120.032 20.2183C119.893 20.1719 119.73 20.1486 119.521 20.1486C119.243 20.1486 118.941 20.1951 118.685 20.2648C118.43 20.3345 118.151 20.4275 117.919 20.5437L117.617 19.9162C117.664 19.8233 117.78 19.7535 117.896 19.7071ZM119.637 22.1937C119.382 22.2402 119.15 22.2866 118.964 22.3331C118.778 22.3796 118.639 22.4493 118.523 22.519C118.407 22.5888 118.337 22.6585 118.268 22.7514C118.221 22.8212 118.175 22.9141 118.151 22.9838C118.128 23.0768 118.128 23.1465 118.128 23.2162C118.128 23.4951 118.198 23.7275 118.36 23.8669C118.523 24.0296 118.755 24.0993 119.103 24.0993C119.312 24.0993 119.521 24.0761 119.707 24.0064C119.893 23.9366 120.055 23.8437 120.171 23.7275C120.287 23.6113 120.404 23.4719 120.473 23.2859C120.543 23.1233 120.589 22.9141 120.589 22.705V22.0775C120.218 22.124 119.893 22.1704 119.637 22.1937Z"
              fill="white"
            />
            <path
              d="M124.025 18.1268H123.097V17.1972H124.025V18.1268ZM123.979 24.7268H123.166V19.4514H123.979V24.7268Z"
              fill="white"
            />
            <path
              d="M126.626 24.7268H125.813V17.1972H126.626V24.7268Z"
              fill="white"
            />
            <path
              d="M129.017 22.9141C129.064 23.1465 129.157 23.3324 129.273 23.5183C129.389 23.7042 129.551 23.8437 129.76 23.9366C129.969 24.0528 130.225 24.0993 130.55 24.0993C130.689 24.0993 130.828 24.0761 130.968 24.0528C131.107 24.0296 131.223 23.9831 131.339 23.9366C131.455 23.8901 131.571 23.8204 131.687 23.7507C131.803 23.681 131.919 23.6113 132.036 23.5183L132.361 24.169C132.105 24.3549 131.827 24.5176 131.548 24.6106C131.269 24.7035 130.921 24.7732 130.526 24.7732C130.109 24.7732 129.737 24.7035 129.412 24.5641C129.11 24.4246 128.855 24.2387 128.646 24.0063C128.437 23.7739 128.298 23.4951 128.205 23.1697C128.112 22.8444 128.065 22.4958 128.065 22.1239C128.065 21.7289 128.112 21.357 128.228 21.0084C128.344 20.6599 128.483 20.381 128.692 20.1253C128.901 19.8697 129.133 19.6838 129.435 19.5444C129.737 19.4049 130.062 19.3352 130.41 19.3352C130.735 19.3352 131.037 19.4049 131.293 19.5211C131.548 19.6373 131.757 19.8 131.943 20.0091C132.128 20.2183 132.244 20.4739 132.337 20.7528C132.43 21.0317 132.477 21.357 132.477 21.6824C132.477 21.7753 132.477 21.8451 132.477 21.9148C132.477 21.9845 132.453 22.0775 132.453 22.1937H128.901C128.924 22.4725 128.971 22.7049 129.017 22.9141ZM129.876 20.1718C129.714 20.2415 129.575 20.3345 129.458 20.4739C129.342 20.6134 129.226 20.7761 129.157 20.962C129.064 21.1479 129.017 21.3803 128.994 21.6127H131.711C131.711 21.4035 131.687 21.1944 131.618 21.0084C131.548 20.8225 131.478 20.6599 131.362 20.5204C131.246 20.381 131.13 20.2648 130.968 20.1951C130.805 20.1253 130.619 20.0789 130.41 20.0789C130.201 20.0556 130.039 20.1021 129.876 20.1718Z"
              fill="white"
            />
            <path
              d="M134.752 20.381C134.822 20.2415 134.915 20.1253 134.985 20.0091C135.054 19.893 135.17 19.8 135.263 19.707C135.356 19.6141 135.472 19.5444 135.588 19.4979C135.704 19.4514 135.844 19.4282 135.983 19.4282C136.053 19.4282 136.145 19.4282 136.238 19.4514C136.331 19.4514 136.424 19.4746 136.54 19.4979L136.447 20.3577C136.331 20.3345 136.215 20.3113 136.145 20.3113C136.076 20.3113 135.983 20.288 135.913 20.288C135.565 20.288 135.286 20.381 135.077 20.5669C134.868 20.7528 134.776 21.0317 134.776 21.3803V24.6803H133.963V20.381L133.87 19.4746H134.636L134.752 20.381Z"
              fill="white"
            />
            <path
              d="M94.6792 0.185913H94.2148V33.0465H94.6792V0.185913Z"
              fill="white"
            />
          </svg>
        </Link>
        <div className="web-section">
          <a href="tel:+18663454587">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.5359 7.59613C14.4647 5.90567 13.8308 4.46893 12.6343 3.2859C11.4377 2.10287 9.97767 1.47627 8.25408 1.40609V0C9.30091 0.0280727 10.2854 0.246101 11.2075 0.654082C12.1296 1.06205 12.9365 1.60886 13.6283 2.2945C14.3201 2.98015 14.873 3.77746 15.287 4.68643C15.701 5.5954 15.9223 6.5653 15.9508 7.59613H14.5359ZM10.78 7.59613C10.7088 6.93399 10.438 6.37269 9.96761 5.91223C9.49723 5.45178 8.92605 5.18646 8.25408 5.11628V3.72209C9.32242 3.79227 10.2305 4.19429 10.9783 4.92816C11.7262 5.66202 12.1357 6.55134 12.2069 7.59613H10.78ZM14.7486 15.9896C13.0472 15.9896 11.3203 15.5887 9.56801 14.7868C7.81571 13.9849 6.19187 12.8471 4.6965 11.3736C3.20114 9.90011 2.04455 8.29798 1.22673 6.56725C0.40891 4.83653 0 3.1369 0 1.46837C0 1.11719 0.120323 0.8219 0.36097 0.582496C0.601631 0.343091 0.898429 0.22339 1.25137 0.22339H4.24271C4.57901 0.22339 4.85663 0.319357 5.07557 0.51129C5.2945 0.703223 5.44453 0.967934 5.52566 1.30542L6.10104 3.76032C6.14665 4.05708 6.13957 4.31988 6.0798 4.54873C6.02003 4.7776 5.89934 4.97356 5.71772 5.13664L3.53761 7.18697C3.87576 7.7411 4.23527 8.26105 4.61616 8.74682C4.99704 9.23261 5.42469 9.69825 5.8991 10.1437C6.39394 10.6533 6.90318 11.1074 7.42681 11.5059C7.95045 11.9044 8.49251 12.2483 9.05301 12.5375L11.1432 10.4385C11.342 10.2286 11.5673 10.0876 11.8191 10.0156C12.0708 9.94356 12.3314 9.93746 12.6008 9.99727L14.9019 10.509C15.2444 10.6048 15.5131 10.769 15.7078 11.0015C15.9026 11.234 16 11.5144 16 11.8428V14.7565C16 15.1088 15.8782 15.4024 15.6347 15.6373C15.3912 15.8722 15.0958 15.9896 14.7486 15.9896ZM2.69129 5.69574L4.40993 4.05258L3.93056 1.90318H1.7168C1.73722 2.44511 1.82112 3.02235 1.9685 3.6349C2.11586 4.24746 2.35679 4.93441 2.69129 5.69574ZM10.6119 13.3862C11.1556 13.637 11.7513 13.8426 12.3988 14.0031C13.0463 14.1636 13.6785 14.2619 14.2953 14.2979V12.0948L12.2757 11.6743L10.6119 13.3862Z"
                fill="white"
              />
            </svg>
            <span>866-345-4587</span>
          </a>
          <p>Get Help From A Human, 24/7</p>
        </div>
        <div className="mobile-section">
          <button className="mobile-button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M14.5359 7.59613C14.4647 5.90567 13.8308 4.46893 12.6343 3.2859C11.4377 2.10287 9.97767 1.47627 8.25408 1.40609V0C9.30091 0.0280727 10.2854 0.246101 11.2075 0.654082C12.1296 1.06205 12.9365 1.60886 13.6283 2.2945C14.3201 2.98015 14.873 3.77746 15.287 4.68643C15.701 5.5954 15.9223 6.5653 15.9508 7.59613H14.5359ZM10.78 7.59613C10.7088 6.93399 10.438 6.37269 9.96761 5.91223C9.49723 5.45178 8.92605 5.18646 8.25408 5.11628V3.72209C9.32242 3.79227 10.2305 4.19429 10.9783 4.92816C11.7262 5.66202 12.1357 6.55134 12.2069 7.59613H10.78ZM14.7486 15.9896C13.0472 15.9896 11.3203 15.5887 9.56801 14.7868C7.81571 13.9849 6.19187 12.8471 4.6965 11.3736C3.20114 9.90011 2.04455 8.29798 1.22673 6.56725C0.40891 4.83653 0 3.1369 0 1.46837C0 1.11719 0.120323 0.8219 0.36097 0.582496C0.601631 0.343091 0.898429 0.22339 1.25137 0.22339H4.24271C4.57901 0.22339 4.85663 0.319357 5.07557 0.51129C5.2945 0.703223 5.44453 0.967934 5.52566 1.30542L6.10104 3.76032C6.14665 4.05708 6.13957 4.31988 6.0798 4.54873C6.02003 4.7776 5.89934 4.97356 5.71772 5.13664L3.53761 7.18697C3.87576 7.7411 4.23527 8.26105 4.61616 8.74682C4.99704 9.23261 5.42469 9.69825 5.8991 10.1437C6.39394 10.6533 6.90318 11.1074 7.42681 11.5059C7.95045 11.9044 8.49251 12.2483 9.05301 12.5375L11.1432 10.4385C11.342 10.2286 11.5673 10.0876 11.8191 10.0156C12.0708 9.94356 12.3314 9.93746 12.6008 9.99727L14.9019 10.509C15.2444 10.6048 15.5131 10.769 15.7078 11.0015C15.9026 11.234 16 11.5144 16 11.8428V14.7565C16 15.1088 15.8782 15.4024 15.6347 15.6373C15.3912 15.8722 15.0958 15.9896 14.7486 15.9896ZM2.69129 5.69574L4.40993 4.05258L3.93056 1.90318H1.7168C1.73722 2.44511 1.82112 3.02235 1.9685 3.6349C2.11586 4.24746 2.35679 4.93441 2.69129 5.69574ZM10.6119 13.3862C11.1556 13.637 11.7513 13.8426 12.3988 14.0031C13.0463 14.1636 13.6785 14.2619 14.2953 14.2979V12.0948L12.2757 11.6743L10.6119 13.3862Z"
                fill="white"
              />
            </svg>{" "}
            <a
              href="tel:+18663454587"
              style={{ color: "white" }}
            >
              {" "}
              Call{" "}
            </a>
          </button>
        </div>
      </section>
    </>
  );
};

export default Header;
