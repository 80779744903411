import React from "react";
import "./dealContact.scss";
import deal from "../../../assets/images/deal.png";

const DealContact = () => {
  return (
    <>
      <section id="deal-contact-wrapper">
        <div className="deals-wrapper">
          <img src={deal} alt="Xfinity Internet + TV Deals" />
          <div className="content">
            <h2>Xfinity Internet + TV Deals</h2>
            <p>
              Get the best deals on speedy Internet and X1 TV, your gateway to
              top-notch entertainment. Xfinity delivers premier in-home WiFi and
              a vast selection of free TV shows and movies on the move.
              Moreover, with a switch to Xfinity, benefit from added perks like
              the X1 DVR, X1 Voice Remote, robust online security, and access to
              countless WiFi hotspots across the country.
            </p>
          </div>
        </div>
        <div className="contact-us">
          <a href="tel:+18663454587">
            Call Now to speak with an expert &nbsp;&nbsp; &nbsp; (866-345-4587)
          </a>
        </div>
        <div className="contact-us-mobile">
          <a href="tel:+18663454587">
            Call Now to speak with an expert<p>(866-345-4587)</p>
          </a>
        </div>
      </section>
    </>
  );
};

export default DealContact;
