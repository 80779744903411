import React from "react";
import internet from "../../../assets/images/internet.png";
import cable from "../../../assets/images/cable.png";
import "./connection.scss";

const Connection = () => {
  return (
    <>
      <section id="connection-wrapper">
        <div className="row">
        <div className="col-md-6 image mob-show">
            <img src={internet} alt="internet" />
          </div>
          <div className="col-md-6">
            <h2>Fastest Internet Connection</h2>
            <p>
              Fast speeds, worldwide coverage, and secure data connections make
              Xfinity Internet revolutionary uncapped browsing, streaming, and
              gaming. Award-winning customer support is quick. Xfinity Internet
              is the best choice for homes and businesses for a connected
              future.
            </p>
            <button onClick={() => (window.location.href = "tel:+18663454587")}>
              Order Now
            </button>
          </div>
          <div className="col-md-6 image mob-hid">
            <img src={internet} alt="Fastest Internet Connection" />
          </div>
          <div className="col-md-6 image">
            <img src={cable} alt="Endless Fun with Cable TV Services" />
          </div>
          <div className="col-md-6">
            <h2>Endless Fun with Cable TV Services</h2>
            <p>
              Xfinity Cable TV offers hundreds of channels, on-demand
              programming, and DVR recording. Enjoy seamless streaming.
              Unlimited entertainment! Xfinity Cable TV provides hundreds of
              channels, on-demand programming, and DVR recording. Enjoy seamless
              streaming. Unlimited entertainment!
            </p>
            <button onClick={() => (window.location.href = "tel:+18663454587")}>
              Order Now
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Connection;
