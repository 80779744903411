import React, { useState } from "react";
import "./otherSection.scss";
import bill from "../../../assets/images/billing.png";
import Faq from "react-faq-component";
import leftimage from "../../../assets/images/left.png";
import rightimage from "../../../assets/images/right.png";
import { toast } from "react-toastify";
import axios from "axios";
import { DotLoader } from "react-spinners";
import Popup from "../../Common/Popup";

const OtherSection = () => {
  const [popupToggle, setPopupToggle] = useState(false);
  const [values, setValues] = useState({
    name: "",
    zip: "",
    phone: "",
    email: "",
  });

  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!values.name) {
      toast.error("Please enter your name to submit the form.");
    } else if (
      !values.phone ||
      !/^\+(?:[0-9] ?){6,14}[0-9]$/.test(values.phone)
    ) {
      toast.error("Please enter your Phone to submit the form.");
    } else if (
      !values.email ||
      !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(values.email)
    ) {
      toast.error("Please enter your email to submit the form.");
    } else if (!values.zip) {
      toast.error("Please enter the zip to submit the form.");
    } else if (values.zip.length !== 5) {
      toast.error("Wrtie 5 digit zip code.");
    } else {
      setLoader(true);

      const payload = {
        to_email: "corp@konnectx.us",
        from_email: values.email,
        subject: values.zip,
        body: `Name: ${values.zip}\nNumber: ${values.phone}`,
        sender_name: values.name,
      };
      axios
        .post("https://everything-backend.herokuapp.com/send_a_email", payload)
        .then((response) => {
          setLoader(false);

          values.name = "";
          values.phone = "";
          values.email = "";
          values.zip = "";
          toast.success("Thank you for your response! :)");
        })
        .catch((err) => {
          setLoader(false);
          console.log({ err });
          toast.error("API Error");
        });
    }
  };

  const data = {
    // title: "FAQ (How it works)",
    rows: [
      {
        title: "What is the process for ordering Xfinity services online?",
        content:
          "Personalize your package according to your preferences, and then follow the instructions to finalize your order. Our team is readily available to provide any necessary information or help you may need.",
      },
      {
        title: "What are the installation fees for Xfinity services?",
        content:
          "Installation fees vary based on the services and package you choose. You'll see the exact costs during the ordering process.We're here to help, so don't hesitate to get in touch if you need more information or assistance.",
      },
      {
        title: "Do packages and deals renew automatically?",
        content:
          "The renewal process for packages and deals varies. Some may renew automatically based on your preferences, while others might require manual renewal. Refer to the terms of each offer.",
      },
      {
        title:
          "How can I reach customer support if I have questions during the ordering process?",
        content:
          "Our customer support team is available to assist you through phone and email. Your questions and inquiries are important to us. Feel free to reach out for assistance.",
      },
      {
        title: "What happens if I don't use all the features in a package?",
        content:
          "It depends on the specific Package. Some packages might have features that can be used later, while others could expire if not utilized during the specified period.",
      },
    ],
  };

  const styles = {
    titleTextColor: "#000034",
    rowTitleColor: "#D6111E",
    textText: "center",
    rowContentPaddingBottom: "20px",
  };

  return (
    <>
      <section id="billing-wrapper">
        <div className="row">
          <div className="col-md-6 image">
            <img src={bill} alt="Make Paying Your Internet and Cable Bills Easy" />
          </div>
          <div className="col-md-6 content">
            <h2>Make Paying Your Internet and Cable Bills Easy</h2>
            <p>
              Experience the convenience of effortless online bill payments for
              your internet and cable services with us. Safeguard sensitive
              information through our advanced encryption while effortlessly
              managing your bills using our customer-friendly service. Enjoy the
              benefits of setting up automatic payments, tracking payment
              history, and unlocking exclusive rewards. Bid farewell to paper
              bills and lengthy waits – opt for digital convenience. Embrace
              efficiency, regain control of your payments.
            </p>
            <button onClick={() => (window.location.href = "tel:+18663454587")}>
              Order Now
            </button>
          </div>
        </div>
      </section>
      <div className="container pt-3 pb-5 mob-padding">
        <h2 className="faq-title-custom">FAQ's (How it works)</h2>
        <p className="faq-define">
          Welcome to exfinityplans.com, where we provide you with the best
          internet and cable TV services in your area. We understand that
          staying connected with your family, friends, and work is essential,
          and that's why we offer fast, reliable, and affordable internet and
          cable TV services. Our services are designed to meet your needs and
          ensure you stay connected all the time.
        </p>
        <Faq data={data} styles={styles} />
      </div>
      <section id="provider-contact-wrapper">
        <div className="row hide-mob">
          <div className="col-md-3 connection-image">
            <img src={leftimage} alt="" />
          </div>
          <div className="col-md-6 contact-us">
            <h3>
              Unlock Your Amazing Future with Just One <span>Connection</span> !
            </h3>
            <a
              onClick={() => setPopupToggle(true)}
              style={{ cursor: "pointer" }}
            >
              Contact Us
            </a>
          </div>
          <div className="col-md-3 connection-image">
            <img src={rightimage} alt="" />
          </div>
        </div>

        <div className="contact-us-show-mob">
          <h3>
            Unlock Your Amazing Future with Just One <span>Connection</span> !
          </h3>
          <a onClick={() => setPopupToggle(true)} style={{ cursor: "pointer" }}>
            Contact Us
          </a>
        </div>
        <div className="connection-image-bottom show-mob">
          <img src={leftimage} alt="" />
          <img src={rightimage} alt="" />
        </div>
      </section>
      {popupToggle && (
        <Popup onClick={() => setPopupToggle(false)}>
          <div className="container" id="contact-us">
            <div className="row">
              <div className="col-md-6 call-now">
                <h2>Let’s Secure Each Other.</h2>
                <p>
                  Have Best Security Service At Your Doorstep. (866) 345 4587
                  Just Give Us a call At and fill the form.
                </p>
                <button
                  onClick={() => (window.location.href = "tel:+18663454587")}
                >
                  Call Now
                </button>
              </div>
              <div className="col-md-6">
                <div className="row form">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      id=""
                      placeholder="Name"
                      value={values.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <input
                      type="number"
                      name="zip"
                      id=""
                      placeholder="ZIP"
                      value={values.zip}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="phone"
                      id=""
                      placeholder="+12345678901"
                      value={values.phone}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-12">
                    <input
                      type="text"
                      name="email"
                      id=""
                      placeholder="Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </div>
                  <p>
                    By clicking the "Get a Free Quote" button below, I agree
                    that an ausss specialist may contact me via text messages or
                    phone calls to the phone number provided by me using
                    automated technology about ausss or LifeShield offers and
                    consent is not required to make a purchase.
                  </p>
                  <button onClick={handleFormSubmit}>
                    {loader ? <DotLoader color="#fff" size={25} /> : "Submit"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Popup>
      )}
    </>
  );
};

export default OtherSection;
