import React from "react";
import "./termsAndCondition.scss";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <section id="terms-and-condition">
        <h1>Xfinity Special Terms and Conditions</h1>
        <p>
          <b>$64.99 Xfinity Choice Double Play:</b> Limited time offer.
          Restrictions apply. Not available in all areas. New residential
          customers only. Equipment, installation, taxes and fees, Broadcast TV
          Fee (up to $14.95/mo.), Regional Sports Fee (up to $8.75/mo.) and
          other applicable charges extra, and subject to change during and after
          the promo. ©️2023 Comcast. All rights reserved.
          <br />
          <br />
          <b>$39.99 Internet:</b> Limited time offer. Restrictions apply. Not
          available in all areas. New residential customers only. Limited to
          Performance Internet. Equipment, installation, taxes and fees, and
          other applicable charges extra, and subject to change during and after
          the promo. After promo, or if any service is cancelled or downgraded,
          regular rates apply. Comcast’s monthly service charge for Performance
          Internet is $39.99 (subject to change). Service limited to a single
          outlet. May not be combined with other offers. Actual speeds vary and
          are not guaranteed. For factors affecting speed visit
          www.xfinity.com/networkmanagement. All devices must be returned when
          service ends. Xfinity hotspots included with Xfinity Internet
          Performance (and above). Limited hotspot access included for
          Performance Starter and below. Available in select areas. 30-Day
          Money-Back Guarantee applies to one month’s recurring service and
          standard installation charges up to $500. ©️2023 Comcast. All rights
          reserved.
          <br />
          <br />
          <b>$30.00 TV:</b> Restrictions apply. Not available in all areas. New
          residential customers only. Limited to Choice TV with Limited Basic,
          Streampix, and HD programming. Equipment, installation, taxes and
          fees, Broadcast TV Fee (up to $10.00/mo.), Regional Sports Fee (up to
          $8.25/mo.) and other applicable charges extra. Pricing subject to
          change. Service limited to a single outlet. May not be combined with
          other offers. Limited Basic service subscription required to receive
          other levels of service. On Demand selections subject to charge
          indicated at time of purchase. Not all programming available in all
          areas. 30-Day Money-Back Guarantee applies to one month’s recurring
          service and standard installation charges up to $500. Call for
          restrictions and complete details. ©2023 Comcast. All rights reserved.
          <br />
          <br />
          <b>$69.99 Xfinity Blast! Internet:</b> Limited time offer.
          Restrictions apply. Not available in all areas. New residential
          customers only. Limited to Performance Internet. Equipment,
          installation, taxes and fees, and other applicable charges extra, and
          subject to change during and after the promo. After promo, or if any
          service is cancelled or downgraded, regular rates apply. Comcast’s
          monthly service charge for Performance Internet is $69.99 (subject to
          change). Service limited to a single outlet. May not be combined with
          other offers. Actual speeds vary and are not guaranteed. For factors
          affecting speed visit www.xfinity.com/networkmanagement. All devices
          must be returned when service ends. Xfinity hotspots included with
          Xfinity Internet Performance (and above). Limited hotspot access
          included for Performance Starter and below. Available in select areas.
          30-Day Money-Back Guarantee applies to one month’s recurring service
          and standard installation charges up to $500. ©️2023 Comcast. All
          rights reserved.
          <br />
          <br />
        </p>
      </section>
    </>
  );
};

export default TermsAndConditions;
